import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { rememberPassword } from '../actions/userActions';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';   
import { useTranslation } from 'react-i18next';
import { PreLoginWrapper } from './PreLoginWrapper';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

export const RecoverPassword = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(true);
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (localtoken) {
            navigate('/fields');
        }
    }, [localtoken, navigate]);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const response = await rememberPassword(email);
        if (typeof response === 'string') {
            setError(t(`recover_password.${response}`));
        } else {
            setIsFormVisible(false);
        }
        setLoading(false);
    };

    const handleGoToLogin = () => {
        navigate('/login');
    };

    return (
        <PreLoginWrapper error={error}>
            {isFormVisible ? ( 
                <form className='w-full' onSubmit={handleSubmit}>
                    <p className="p-component mb-5 font-semibold w-full">{t('recover_password.enter_email')}</p>
                    <IconField className="w-full mb-5" iconPosition="left">
                        <InputIcon className="pi pi-envelope ml-2" style={{ color: 'black' }}/>
                        <InputText
                            type="email"
                            className='w-full pl-6'
                            placeholder={t('recover_password.placeholder_email')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </IconField>

                    <Button 
                        className='bg-primary w-full' 
                        disabled={loading} 
                        label={loading ? t('recover_password.loading_message') : t('recover_password.remember_password')}
                    />

                    <div className="flex flex-column align-items-center justify-content-between mt-5">
                        <a className="no-underline cursor-pointer" onClick={() => navigate('/login')}>{t('recover_password.already_account')} <span className="text-white no-underline cursor-pointer" onClick={() => navigate('/login')}>{t('recover_password.go_to_login')}</span></a>
                    </div>
                </form>
            ) : (
                <>
                    <p className="p-success font-semibold text-center mb-5">{t('recover_password.check_email')}</p>
                    <Button className='bg-primary w-full justify-content-center' disabled={loading} onClick={handleGoToLogin}>
                        {loading ? t('recover_password.loading_message') : t('recover_password.go_to_login')}
                    </Button>
                </>
            )}
        </PreLoginWrapper>
    );
};
