import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../actions/userActions';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import 'react-phone-number-input/style.css';
import { Checkbox } from 'primereact/checkbox';
import { PreLoginWrapper } from './PreLoginWrapper';
import { Dropdown } from 'primereact/dropdown';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Image } from 'primereact/image';

export const Register = () => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [error, setError] = useState('');
    const [receibeAdvertising, setReceibeAdvertising] = useState(false);
    const [cifNif, setCifNif] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [loyaltyProgramId, setLoyaltyProgramId] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        if (localtoken) {
            navigate('/fields');
        }
        const countriesData = t('countries:data', { returnObjects: true });
        setCountries(countriesData);
        
    }, [localtoken, navigate]);

    const handleNext = () => {
        setError('');
        if (step === 1 && (name === '' || surname === '' || email === '' || password === '')) {
            return;
        }
        setError('');
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const countryCode = selectedCountry.code

        const response = await register(name, surname, email, password, receibeAdvertising, phone, streetNumber, city, postalCode, countryCode, cifNif, companyName, loyaltyProgramId, navigate);
        
        if (typeof response === 'string') {
            setError(t(`register.${response}`));
            setStep(1);
        }
    };

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <Image alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} style={{ width: '18px', marginRight: '16px', marginLeft: '6px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span className="p-input-icon-left" style={{ display: 'flex', alignItems: 'center'}}>
                <i className="pi pi-globe" style={{ marginLeft: '7px', color: 'black'  }} />
                <span style={{ marginLeft: '39px' }}>{props.placeholder}</span>
            </span>
        );
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <Image alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <PreLoginWrapper error={error}>
            <form className='w-full' onSubmit={handleSubmit}>
                {step === 1 && (
                    <>
                        <p className="p-component font-semibold w-full text-center mb-5">{t('register.step1')}</p>
                        <IconField className="w-full" iconPosition="left">
                            <InputIcon className="pi pi-user ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_name')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full pl-6"
                                required
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-user ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_surname')}
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                className="w-full pl-6"
                                required
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-envelope ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_email')}
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full pl-6"
                                required
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-lock ml-2" style={{ color: 'black', zIndex: 1 }}/>
                            <Password
                                placeholder={t('register.placeholder_password')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                toggleMask
                                inputClassName="w-full pl-6"
                                className="w-full"
                                pt={{ iconField: { root: { className: 'w-full' } } }} 
                                required
                                promptLabel={t('register.prompt_label')}
                                weakLabel={t('register.weak_label')}
                                mediumLabel={t('register.medium_label')}
                                strongLabel={t('register.strong_label')}
                            />
                        </IconField>
                        <div className="flex align-items-center mt-3">
                            <Checkbox
                                inputId="loyalty-checkbox"
                                checked={receibeAdvertising}
                                onChange={(e) => setReceibeAdvertising(e.checked)}
                            />
                            <label htmlFor="loyalty-checkbox" className="ml-3">
                                {t('register.checkbox_message')}
                            </label>
                        </div>
                        <Button
                            label={t('register.next')}
                            onClick={handleNext}
                            className="bg-primary mt-5 w-5"
                            style={{ float: 'right' }}
                        />
                    </>
                )}
                {step === 2 && (
                    <>
                        <p className="p-component font-semibold w-full text-center mb-5">{t('register.step2')}</p>
                        <IconField className="w-full" iconPosition="left">
                            <InputIcon className="pi pi-id-card ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_cif_nif')}
                                value={cifNif}
                                onChange={(e) => setCifNif(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-building ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_company_name')}
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-key ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_loyalty_program_id')}
                                value={loyaltyProgramId}
                                onChange={(e) => setLoyaltyProgramId(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <div className="flex justify-content-between w-full mt-5">
                            <Button 
                                className="text-white" 
                                link 
                                label={<><i className="pi pi-arrow-left mr-2"></i> {t('register.back')}</>} 
                                onClick={handleBack}
                            />
                            <Button
                                label={t('register.next')}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNext();
                                }}
                                className="bg-primary w-5"
                            />
                        </div>
                    </>
                )}
                {step === 3 && (
                    <>
                        <p className="p-component font-semibold w-full text-center mb-5">{t('register.step3')}</p>
                        <Dropdown 
                            filter
                            value={selectedCountry} 
                            onChange={(e) => setSelectedCountry(e.value)} 
                            options={countries} 
                            optionLabel="name" 
                            placeholder={t('register.placeholder_country')} 
                            valueTemplate={selectedCountryTemplate} 
                            itemTemplate={countryOptionTemplate} 
                            className="w-full" 
                            dropdownIcon={(opts) => {
                                return opts.iconProps['data-pr-overlay-visible'] ? <FaChevronRight {...opts.iconProps} /> : <FaChevronDown {...opts.iconProps} />;
                            }}
                        />
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-map-marker ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_city')}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-map-marker ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_street_number')}
                                value={streetNumber}
                                onChange={(e) => setStreetNumber(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-map-marker ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_postal_code')}
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-phone ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('register.placeholder_phone')}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="w-full pl-6"
                                pattern="^\d{6,}$"
                                title={t('register.phone_pattern')}
                            />
                        </IconField>
                        <div className="flex justify-content-between w-full mt-5">
                            <Button 
                                className="text-white" 
                                link 
                                label={<><i className="pi pi-arrow-left mr-2"></i> {t('register.back')}</>} 
                                onClick={handleBack}
                            />
                            <Button
                                label={loading ? t('register.loading_message') : t('register.register_message')}
                                disabled={loading}
                                className="bg-primary w-5"
                            />
                        </div>
                    </>
                )}
            </form>
            <div className="flex flex-column align-items-center justify-content-between mt-5">
                <a className="no-underline" >{t('register.already_account')} <a className="text-white no-underline cursor-pointer" onClick={() => navigate('/login')}>{t('register.go_to_login')}</a></a>
            </div>
        </PreLoginWrapper>
    );
};
