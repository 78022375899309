import React, { useState } from 'react';
import nematool_logo from '../assets/nematool.png';
import { useNavigate } from 'react-router-dom';
import { logout, deleteAccount } from '../actions/userActions';
import { ModalGeneric } from './ModalGeneric'; // Importa el componente del modal
import { Image } from 'primereact/image'; // Nuevo import
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

export const Settings = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false); // Estado para controlar el modal de cerrar sesión
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Estado para controlar el modal de eliminar cuenta

    const handleUpdateUser = () => {
        navigate('/updateUser');
    };

    const handleLogOut = () => {
        setIsLogoutModalOpen(true); // Abre el modal de cerrar sesión
    };

    const confirmLogOut = () => {
        logout(navigate);
        setIsLogoutModalOpen(false); // Cierra el modal después de la acción
    };

    const cancelLogOut = () => {
        setIsLogoutModalOpen(false); // Cierra el modal sin cerrar sesión
    };

    const handleDeleteAccount = () => {
        setIsDeleteModalOpen(true); // Abre el modal de eliminar cuenta
    };

    const confirmDeleteAccount = () => {
        deleteAccount(navigate);
        setIsDeleteModalOpen(false); // Cierra el modal después de la acción
    };

    const cancelDeleteAccount = () => {
        setIsDeleteModalOpen(false); // Cierra el modal sin eliminar
    };

    const redirectToTerms = () => {
        window.location.href = "https://nematool.com/Nematool-UserLicenseAgreement.pdf";
    };

    const redirectToPolicy = () => {
        window.location.href = "https://nematool.com/Nematool-DataPrivacyStatement.pdf";
    };

    return (
        <PostLoginWrapper>
            <div className="flex justify-content-center">
                <Image 
                    src={nematool_logo}
                    alt="Profile" 
                    width="150"
                />
            </div>
            <p className="text-xl text-center mb-4 font-semibold">{t('settings.version')}</p>
            <div className="flex flex-column align-items-center w-full">
                <Button 
                    label={t('settings.update_user')} 
                    className="bg-primary w-11" 
                    onClick={handleUpdateUser} 
                />
                <Divider />
                <Button 
                    label={t('settings.log_out')} 
                    className="p-button-danger mb-3 w-11" 
                    onClick={handleLogOut} 
                />
                <Button 
                    label={t('settings.delete_account')} 
                    className="p-button-danger w-11" 
                    onClick={handleDeleteAccount} 
                />
                <Divider />
                <Button 
                    label={t('settings.terms_and_conditions')} 
                    className="p-button-secondary mb-3 w-11" 
                    onClick={redirectToTerms} 
                />
                <Button 
                    label={t('settings.privacy_policy')} 
                    className="p-button-secondary w-11" 
                    onClick={redirectToPolicy} 
                />
            </div>

            <ModalGeneric 
                isOpen={isLogoutModalOpen} 
                onClose={cancelLogOut} 
                onConfirm={confirmLogOut}
                message={t('settings.logout_message')}
            />

            <ModalGeneric 
                isOpen={isDeleteModalOpen} 
                onClose={cancelDeleteAccount} 
                onConfirm={confirmDeleteAccount}
                message={t('settings.delete_account_message')}
            />
        </PostLoginWrapper>
    );
};
