import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Header } from './Header';
import { Html5Qrcode } from 'html5-qrcode';
import { putLicense, isValidSerialNumber } from '../actions/fieldsActions';

export const AddLicense = () => {
    const dispatch = useDispatch();
    const [licenseCode, setLicenseCode] = useState('');
    const [scanning, setScanning] = useState(false);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const sensorNumber = location.state?.sensorNumber;

    useEffect(() => {
        const scanner = new Html5Qrcode("reader");
        setHtml5QrCode(scanner);

        return () => {
            if (scanner.isScanning) {  // Verifica si el escáner está en funcionamiento
                scanner.stop().catch((err) => console.error("Error al detener el escaneo:", err));
            }
        };
    }, [dispatch]);

    const startScan = () => {
        if (html5QrCode && !scanning) {
            setScanning(true);
            setErrorMessage('');
    
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const qrboxSize = Math.min(viewportWidth, viewportHeight) * 0.8; // 80% del tamaño más pequeño
    
            html5QrCode.start(
                { facingMode: "environment" },
                {
                    fps: 10,
                    qrbox: { width: qrboxSize, height: qrboxSize }
                },
                (decodedText) => {
                    setLicenseCode(decodedText);
                    html5QrCode.stop()
                        .then(() => {
                            setScanning(false);
                        })
                        .catch((err) => console.error("Error al detener el escaneo:", err));
                }
            ).catch((err) => {
                console.error(`Error inicializando el escaneo: ${err}`);
                setErrorMessage('Error inicializando el escaneo. Por favor, inténtalo de nuevo.');
                setScanning(false);
            });
        }
    };
    
    const validateLicense = async () => {
        try {
            const sensor = await isValidSerialNumber(sensorNumber);
            if (typeof sensor === 'string') {
                setErrorMessage(sensor);
                return;
            }
            const sensorId = sensor.results[0].id
            const isValid = await putLicense(licenseCode, sensorId);
            if (typeof isValid === 'string') {
                setErrorMessage(isValid);
            } else {
                navigate('/fields');
            }
        } catch (error) {
            setErrorMessage("An error occurred while validating the serial number. Try again.");
        }
    };

    const handleInputChange = (e) => {
        setLicenseCode(e.target.value);
    };

    return (
        <div>
            <Header />
            <div className={`qr-div ${scanning ? 'scanning-active' : ''}`}>
                <h2>Enter the license code:</h2>
                <input 
                    className='qr-input'
                    type="text" 
                    value={licenseCode} 
                    onChange={handleInputChange} 
                    placeholder=""
                />
                <div>
                    {/* <button
                        className="qr-button"
                        onClick={startScan}
                        style={{ display: scanning ? 'none' : 'block' }}
                    >
                        {scanning ? "" : "Start scan with QR"}
                    </button> */}
                    <button
                        className="qr-button"
                        onClick={validateLicense}
                    >
                        Validate
                    </button>
                </div>
                {errorMessage && <strong><p className="error-message">{errorMessage}</p></strong>}
                <div id="reader" className={`reader ${scanning ? 'fullscreen' : ''}`}></div>
            </div>
        </div>
    );
};
