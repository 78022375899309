// src/utils/fieldUtils.js

export const getSignalStatus = (rssi) => {
    const rssiValue = parseInt(rssi, 10);

    if (rssiValue > -85) {
        return 'well_signal';
    } else if (rssiValue <= -85 && rssiValue >= -95) {
        return 'half_signal';
    } else if (rssiValue <= -96 && rssiValue >= -119) {
        return 'low_signal';
    } else if (rssiValue <= -120) {
        return 'no_signal';
    }
};

export const getModel = (field) => {
    if (field['active_prediction_model']){
        let value = 0;
        let color = 'green';
        let generation = 0;
        let value_j2 = 0;
        let color_j2 = 'green';
        let generation_j2 = 0;
        if (field['active_prediction_model']['last_log_entry']){
            value = parseInt(field['active_prediction_model']['last_log_entry']['ratio_eggs'] * 100);
            generation = field['active_prediction_model']['last_log_entry']['generation']
            if (value >= 80 && value < 100) {
                color = 'orange';
            } else if (value >= 100 && value < 120) {
                value = 100;
                color = 'red';
            } else if (value >= 120) {
                value = 100;
                color = 'blue';
            }
        }
        if (field['active_prediction_model']['last_log_entry_j2']){
            generation_j2 = field['active_prediction_model']['last_log_entry_j2']['generation'] 
            value_j2 = field['active_prediction_model']['last_log_entry_j2']['ratio_larvicide']
            color_j2 = field['active_prediction_model']['last_log_entry_j2']['color']
        }
        return ['Nematode active',generation,value,color,generation_j2,value_j2,color_j2];
    }
    else if (field['active_solarization']){
        let degrees = 0;
        if (field['active_solarization']['degrees']){
            degrees = parseInt(field['active_solarization']['degrees']);
        }
        let widthBar = (degrees/3000) * 100;
        return ['Solarization active',degrees,widthBar];
    }
    return ['No model'];
};

export const getModelName = (field) => {
    if (field['active_prediction_model']){
        return 'nematode';
    }
    else if (field['active_solarization']){
        return 'solarization';
    }
    return 'no_model';
};

export const getModelId = (field) => {
    if (field['active_prediction_model']){
        return ["N",field['active_prediction_model']['id']];
    }
    else if (field['active_solarization']){
        return ["S",field['active_solarization']['id']];
    }
    return null;
};
export const getOwner = (field) => {
    return field['owner']['email'];
};
