import { getField } from '../actions/fieldsActions';
const apiUrl = process.env.REACT_APP_API_URL;

export const postNematode = async (fieldId) => {

    try {
        const localtoken = localStorage.getItem('authToken'); // Asegúrate de obtener el token aquí
        const response = await fetch(`${apiUrl}/api/v1/nematool/prediction_models/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ field: fieldId }), // Asegúrate de enviar el ID del campo
        });

        if (response.ok) {
            const data = await response.json();

            getField(fieldId);
        } else {
            const errorData = await response.json();

            if (response.status === 401) {
                return "Error: Credenciales incorrectas"
            } else {
                return errorData.error || "Error desconocido"
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        return error.message
    }
};

export const postSolarization = async (fieldId) => {
    try {
        const localtoken = localStorage.getItem('authToken'); // Asegúrate de obtener el token aquí
        const response = await fetch(`${apiUrl}/api/v1/nematool/solarizations/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ field: fieldId }), // Asegúrate de enviar el ID del campo
        });

        if (response.ok) {
            const data = await response.json();

            getField(fieldId);
        } else {
            const errorData = await response.json();

            if (response.status === 401) {
                return "Error: Credenciales incorrectas"
            } else {
                return errorData.error || "Error desconocido"
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        return error.message
    }
};

export const putNematode = async (fieldId, nematodeId) => {
    try {
        const localtoken = localStorage.getItem('authToken'); // Asegúrate de obtener el token aquí
        const response = await fetch(`${apiUrl}/api/v1/nematool/prediction_models/${nematodeId}/stop/`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();

            getField(fieldId);
        } else {
            const errorData = await response.json();

            if (response.status === 401) {
                return "Error: Credenciales incorrectas"
            } else {
                return errorData.error || "Error desconocido"
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        return error.message
    }
};

export const putSolarization = async (fieldId, solarizationId) => {
    try {
        const localtoken = localStorage.getItem('authToken'); // Asegúrate de obtener el token aquí
        const response = await fetch(`${apiUrl}/api/v1/nematool/solarizations/${solarizationId}/stop/`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();

            getField(fieldId);
        } else {
            const errorData = await response.json();

            if (response.status === 401) {
                return "Error: Credenciales incorrectas"
            } else {
                return errorData.error || "Error desconocido"
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        return error.message
    }
};

