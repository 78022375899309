import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Header } from './Header';
import { getCrops, postField, postSensorToField } from '../actions/fieldsActions';
import { useLocation, useNavigate } from 'react-router-dom';
import Map from './Map';

export const FormField = () => {
    const location = useLocation();
    const navigate = useNavigate(); 
    const { sensorId } = location.state || {};
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [crops, setCrops] = useState([]); 
    const [selectedCrop, setSelectedCrop] = useState(""); 
    const [fieldName, setFieldName] = useState(""); 
    const [selectedLocation, setSelectedLocation] = useState(null); 

    useEffect(() => {
        if (sensorId  === undefined){
            navigate(-1)
        }
        getAllCrops();
    }, [dispatch]);

    const getAllCrops = async () => {
        try {
            const response = await getCrops();
            setCrops(response.results); 
        } catch (error) {
            setErrorMessage("An error occurred while fetching the crops. Try again.");
        }
    };

    const handleCropChange = (e) => {
        setSelectedCrop(e.target.value); // Almacena el id del cultivo seleccionado
    };

    const handleFieldNameChange = (e) => {
        setFieldName(e.target.value); 
    };

    const handleMapLoad = useCallback((initialPosition) => {
        setSelectedLocation(initialPosition); 
    }, []);

    const handleMapClick = (position) => {
        setSelectedLocation(position); 
    };

    const handlePostField = async () => {
        if (fieldName === ""){
            setErrorMessage("The name cannot be null");
            return;
        }
        if (selectedCrop === ""){
            setErrorMessage("You must select a crop");
            return;
        }
        setErrorMessage("");
        const fieldId = await postField(fieldName, selectedCrop, selectedLocation);
        const responseStatus = await postSensorToField(fieldId, sensorId);
        if (responseStatus !== 200){
            setErrorMessage("An error occurred while creating the field");
            return;
        }
        navigate(`/field/${fieldId}`);
    };

    return (
        <div>
            <Header />
            <div className='form-field-div'>
                <h2>Enter the field name:</h2>
                <input 
                    className='form-field-input'
                    type="text" 
                    placeholder="The field name..."
                    value={fieldName} 
                    onChange={handleFieldNameChange} 
                />
                <h2>Select the crop:</h2>
                <select 
                    className='form-field-select'
                    value={selectedCrop} 
                    onChange={handleCropChange}
                >
                    <option value="" disabled hidden>Select a crop</option>
                    {crops.map(crop => (
                        <option key={crop.id} value={crop.id}> {/* Cambiado para usar `id` */}
                            {crop.name}
                        </option>
                    ))}
                </select>
                <h2>Select the location:</h2>
                <Map onMapClick={handleMapClick} onMapLoad={handleMapLoad} />
                {errorMessage && <strong><p className="form-field-message">{errorMessage}</p></strong>}
                <button 
                    className='create-button'
                    onClick={handlePostField}
                >
                    Create
                </button>
            </div>
        </div>
    );
};
