import React, { useState } from 'react';
import { interpolateData } from '../actions/fieldsActions';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'primereact/inputnumber';

export const ModalInterpolate = ({ isOpen, onClose, interpolationPeriod, sensorId, onError }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [value, setValue] = useState(interpolationPeriod);
    const [errorMessage, setErrorMessage] = useState('');

    const handleAccept = async (e) => {
        e.preventDefault();
        const response = await interpolateData(value, sensorId);
        if (typeof response === 'string') {
            setErrorMessage(response);
            onError(response);
        } else {
            window.location.reload(true);
        }
    };

    return (
        <ConfirmDialog
            className="w-11"
            visible={isOpen}
            onHide={onClose}
            message={
                <div className="number-picker-container">
                    {errorMessage && <strong><p className="error-message">{errorMessage}</p></strong>}
                    <h3>{t('interpolate_modal.message')}</h3>
                    <InputNumber
                        value={value}
                        onValueChange={(e) => setValue(e.value)}
                        min={0}
                        max={10}
                    />
                </div>
            }
            header={t('interpolate_modal.header')}
            icon="pi pi-exclamation-triangle"
            accept={handleAccept}
            reject={onClose}
            acceptLabel={t('interpolate_modal.accept_label')}
            rejectLabel={t('interpolate_modal.cancel_label')}
        />
    );
};
