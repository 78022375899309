import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { getUser } from '../actions/userActions';
export const ModalUpdateUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const userData = await getUser();
            const userFields = [
                userData.id,
                userData.first_name,
                userData.last_name,
                userData.phone,
                userData.street_number,
                userData.city,
                userData.zip_code,
                userData.receive_advertising,
                userData.nif,
                userData.company,
                userData.loyalty_id,
                userData.country
            ];

            const hasNullField = userFields.some(field => field === null);

            if (!hasNullField) {
                localStorage.setItem('showUpdateUserModal', 'false');
            }

            const showUpdateUserModal = localStorage.getItem('showUpdateUserModal');
            const remindLaterTimestamp = localStorage.getItem('remindLaterUpdateUserTimestamp');
            const currentTime = new Date().getTime();
            
            // Falta incluir que campos debe actualizar
            // 2592000000 milisegundos = 30 días
            if ((showUpdateUserModal === 'true' || showUpdateUserModal === null) && (!remindLaterTimestamp || currentTime - remindLaterTimestamp > 2592000000)) {
                setIsOpen(true);
            }
        };
        fetchUserData();
    }, []);

    const handleUpdateUser = () => {
        setIsOpen(false);
        navigate('/updateUser');
    };

    return (
        <>
            <ConfirmDialog
                visible={isOpen}
                className="w-11"
                style={{ maxWidth: '500px', color: 'black' }}
                onHide={() => setIsOpen(false)}
                message={<span style={{ color: 'black' }}>{t('update_user_modal.message')}</span>}
                header={<span style={{ color: 'black' }}>{t('update_user_modal.header')}</span>}
                icon="pi pi-exclamation-triangle"
                accept={handleUpdateUser}
                acceptLabel={t('update_user_modal.accept_label')}
                rejectClassName="hidden"
                defaultFocus="none"
                focusOnShow={false}
                closable={false}
            />
        </>
    );
};
