const apiUrl = process.env.REACT_APP_API_URL;

export const getFields = async (setFields) => {
    const localtoken = localStorage.getItem('authToken');
    let nextPage = 1;

    try {
        let fields = [];
        while (nextPage) {
            const response = await fetch(`${apiUrl}/api/v1/fields/?page=${nextPage}&page_size=10`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localtoken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (Array.isArray(data.results)) {
                    fields = [...fields, ...data.results];
                    setFields(fields);
                }
                nextPage = data.next ? nextPage + 1 : null;
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error fetching fields');
            }
        }
    } catch (error) {
        console.error('Error fetching fields:', error.message);
    }
};

export const getField = async (fieldId, setFieldData) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const response = await fetch(`${apiUrl}/api/v1/fields/${fieldId}/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setFieldData(data);
        } else {
            const errorData = await response.json();
            return errorData.error || "Error desconocido"
        }
    } catch (error) {
        return error.message || "Error de red"
    }
};

export const isValidSerialNumber = async (tstSerialNumber) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const params = new URLSearchParams({
            serial: tstSerialNumber
        }).toString();

        const response = await fetch(`${apiUrl}/api/v1/sensors/?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.status === 403) {
            return "This device already belongs to another user. Please verify that it is correct.";
        }
        if (response.ok) {
            const data = await response.json();
            if (data.results.length === 0){
                return "This device does not exist. Please verify that it is correct.";
            }
            return data;
        } else {
            const errorData = await response.json();
            return `Error: ${errorData.message || 'The serial number could not be validated.'}`;
        }
    } catch (error) {
        return "An error occurred while trying to validate the serial number. Please try again later.";
    }
};

export const getCrops = async () => {
    const localtoken = localStorage.getItem('authToken');
    
    const params = new URLSearchParams({
        page_size: 100
    }).toString();

    try {
        const response = await fetch(`${apiUrl}/api/v1/crops/?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const errorData = await response.json();
            return `Error: ${errorData.message || 'The serial number could not be validated.'}`;
        }
    } catch (error) {
        return "An error occurred while trying to validate the serial number. Please try again later.";
    }
};

export const postField = async (name, crop, area) => {
    const localtoken = localStorage.getItem('authToken');
    const coordinates = [area.lng, area.lat];

    try {
        const response = await fetch(`${apiUrl}/api/v1/fields/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name, 
                crop: parseInt(crop, 10), // Convertir `crop` a entero
                area: { coordinates: coordinates }
            })
        });

        if (response.ok) {
            const data = await response.json();
            return data.id;

        } else {
            const errorData = await response.json();
            return `Error: ${errorData.message || 'The serial number could not be validated.'}`;
        }
    } catch (error) {
        return "An error occurred while trying to validate the serial number. Please try again later.";
    }
};

export const postSensorToField = async (fieldId, sensorId) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const response = await fetch(`${apiUrl}/api/v1/sensor_fields/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                field: fieldId,
                sensor: sensorId
            })
        });

        return response.status;
    } catch (error) {
        return "An error occurred while trying to validate the serial number. Please try again later.";
    }
};

export const deleteField = async (fieldId) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const response = await fetch(`${apiUrl}/api/v1/fields/${fieldId}/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            }
        });

        return response.status;
    } catch (error) {
        return "An error occurred while trying to validate the serial number. Please try again later.";
    }
}

export const getGuests = async (fieldId) => {
    const localtoken = localStorage.getItem('authToken');
    
    const params = new URLSearchParams({
        field: fieldId,
        page_size: 100
    }).toString();

    try {
        const response = await fetch(`${apiUrl}/api/v1/beta_guests/?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const errorData = await response.json();
            return `Error: ${errorData.message || 'The serial number could not be validated.'}`;
        }
    } catch (error) {
        return "An error occurred while trying to validate the serial number. Please try again later.";
    }
};

export const deleteGuest = async (fieldId, userId) => {
    const localtoken = localStorage.getItem('authToken');
    
    const params = new URLSearchParams({
        field: fieldId,
        user: userId
    }).toString();

    try {
        const response = await fetch(`${apiUrl}/api/v1/beta_guests/?${params}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        return response
    } catch (error) {
        return "An error occurred while trying to validate the serial number. Please try again later.";
    }
};

export const addGuest = async (fieldId, email) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const response = await fetch(`${apiUrl}/api/v1/guests/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                field: fieldId,
                email: email
            })
        });

        return response.status
    } catch (error) {
        return "An error occurred while trying to validate the serial number. Please try again later.";
    }
};

export const putLicense = async (license_code, sensor_id) => {
    const localtoken = localStorage.getItem('authToken');

    const params = new URLSearchParams({
        license_code: license_code,
        sensor_id: sensor_id
    }).toString();

    try {
        const response = await fetch(`${apiUrl}/api/v1/license?${params}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            }
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const errorData = await response.json();
            return `Error: ${errorData.message || 'The licence code could not be validated.'}`;
        }   
    } catch (error) {
        return "An error occurred while trying to validate the license. Please try again later.";
    }
};

export const patchSensor = async (sensor_id, field_id) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const response = await fetch(`${apiUrl}/api/v1/sensors/${sensor_id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                field: field_id,
            })
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const errorData = await response.json();
            return `Error: ${errorData.message || 'Failure to link the sensor with the field.'}`;
        }   
    } catch (error) {
        return "An error occurred while trying to link the sensor to the field. Please try again later.";
    }
};

export const fetchNotifications = async (setNotifications) => {
    const localtoken = localStorage.getItem('authToken');
    let nextPage = 1;
    let allNotifications = [];

    try {
        while (nextPage) {
            const params = new URLSearchParams({
                page: nextPage,
                page_size: 10
            }).toString();

            const response = await fetch(`${apiUrl}/api/v1/notifications/?${params}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localtoken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (Array.isArray(data.results)) {
                    allNotifications = [...allNotifications, ...data.results];
                    setNotifications(allNotifications);
                }
                nextPage = data.next ? nextPage + 1 : null;
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error fetching notifications');
            }
        }
    } catch (error) {
        console.error('Error fetching notifications:', error.message);
    }
};

export const interpolateData = async (interpolation_period, sensor_id) => {
    const localtoken = localStorage.getItem('authToken');
    try {
        const response = await fetch(`${apiUrl}/api/v1/sensors/${sensor_id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                interpolation_period: interpolation_period
            })
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            if(response.status === 400){
                return `The number of readings must be 48.`;
            }
            return `Unknown error.`;
        }   
    } catch (error) {
        return "An error occurred while trying to link the sensor to the field. Please try again later.";
    }
};
