import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { avalibleLicence, formatDateLicence, expirationDays } from '../utils/functions';
import well_signal from '../assets/well_signal.svg';
import half_signal from '../assets/half_signal.svg';
import low_signal from '../assets/low_signal.svg';
import not_signal from '../assets/not_signal.svg';

export const FieldCard = ({ fieldId, fieldName, sensorNumber, activeSensor, signalSensor, model, ownerEmail, userEmailLogin, expirationLicenseDate, isFields }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let haveSensor = true;
    if (sensorNumber === 'no_sensor'){
        sensorNumber = t('field_card.no_sensor');
        haveSensor = false;
    }

    let licenceStatus = avalibleLicence(expirationLicenseDate);
    
    // Oculto la logica de las licencias
    licenceStatus = true;

    // Maneja el evento de clic en el componente
    const handleClick = () => {
        if (licenceStatus || isFields) {
            navigate(`/field/${fieldId}`);
        }
    };

    const handleClickBuyLicense = () => {
        navigate('/create/field/license', { state: { sensorNumber } });
    };

    const handleClickLinkSensor = () => {
        navigate('/link/sensor', { state: { fieldId } });
    };

    const getSignalIcon = (signalSensor) => {
        switch (signalSensor) {
            case 'well_signal':
                return well_signal;
            case 'half_signal':
                return half_signal;
            case 'low_signal':
                return low_signal;
            default:
                return not_signal;
        }
    };

    return (
        <Card 
            className={`w-11 mt-3 ${!licenceStatus ? 'opacity-60' : ''}`}
            onClick={handleClick}
        >
            <div className="flex justify-content-between align-items-center mb-3">
                {haveSensor ? (
                    <Badge value={activeSensor === 'true' ? `${t('field_card.sensor')} ${t('field_card.active')}: ${sensorNumber} ` : `${t('field_card.sensor')} ${t('field_card.inactive')}: ${sensorNumber}` } severity={activeSensor === 'true' ? 'success' : 'danger'} className="mr-2" />
                ) : (
                    <Badge value={sensorNumber} className="mr-2" severity="danger" />
                )}
                <span className="flex align-items-center font-medium text-color-secondary">{t(`field_card.${signalSensor}`)} 
                    <Image src={getSignalIcon(signalSensor)} alt="signal icon" style={{ marginLeft: '5px' }} pt={{ image: { width: '20px', height: '20px' } }} />
                </span>
            </div>

            <div className="mb-2">
                <h3 
                    className="text-xl font-bold text-color-secondary mb-0" 
                    style={{ overflowWrap: 'break-word'}}
                >
                    {fieldName}
                </h3>
            </div>

            {model[0] === 'Nematode active' && licenceStatus && (
                <div className="mb-3">
                    {model[1] === 0 && (
                        <p className="font-medium text-color-secondary mb-3 mt-0">{t('field_card.generation').charAt(0).toUpperCase() + t('field_card.generation').slice(1)} {t('field_card.initial')}</p>
                    )}
                    {model[1] !== 0 && (
                        <p className="font-medium text-color-secondary mb-3 mt-0">{model[1]}º {t('field_card.generation')}</p>
                    )}
                    <div className="mb-3">
                        <div className="flex justify-content-between align-items-center mb-2">
                            <span className="font-medium text-color-secondary">{t('field_card.egg')}</span>
                            <span className="font-medium text-color-secondary">{model[2]}%</span>
                        </div>
                        <ProgressBar 
                            value={model[2]} 
                            color={model[3]}
                            showValue={false}
                            style={{ borderRadius: '10px', height: '20px' }}
                        />
                    </div>
                    <div>
                        <div className="flex justify-content-between align-items-center mb-2">
                            <span className="font-medium text-color-secondary">{t('field_card.larvicide')}</span>
                            <span className="font-medium text-color-secondary">{model[5]}%</span>
                        </div>
                        <ProgressBar 
                            value={model[5]} 
                            color={model[6]}
                            showValue={false}
                            style={{ borderRadius: '10px', height: '20px' }}
                        />
                    </div>
                </div>
            )}

            {model[0] === 'Solarization active' && licenceStatus && (
                <div className="mb-3">
                    <div className="flex justify-content-between align-items-center mb-2">
                        <span className="font-medium text-color-secondary">Degrees</span>
                        <span className="font-medium text-color-secondary">{model[1]}ºC</span>
                    </div>
                    <ProgressBar 
                        value={model[2]} 
                        color="orange"
                        showValue={false}
                        style={{ borderRadius: '10px', height: '20px' }}
                    />
                </div>
            )}

            <div className="pt-1">
                <span 
                    className="font-medium m-0 text-color-secondary" 
                    style={{ wordWrap: 'break-word', overflowWrap: 'break-word'}}
                >
                    {t('field_card.owner')}: {userEmailLogin === ownerEmail ? t('field_card.you') : ownerEmail}
                </span>
            </div>
        </Card>
    );
};