import React, { useState, useEffect } from 'react';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Login } from './components/Login';
import { Register } from './components/Register';
import { RecoverPassword } from './components/RecoverPassword';
import { Fields } from './components/Fields';
import { Field } from './components/Field';
import { Settings } from './components/Settings';
import { QRScan } from './components/QRScan';
import { FormField } from './components/FormField';
import PrivateRoute from './components/PrivateRoute';
import { AddLicense } from './components/AddLicense';
import { LinkSensor } from './components/LinkSensor';
import { Notifications } from './components/Notifications';
import { RestorePassword } from './components/RestorePassword';
import { UpdateUser } from './components/UpdateUser';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { ModalNetwork } from './components/ModalNetwork';
import './i18n';


function App() {
  const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(false);
  const isProduction = process.env.NODE_ENV === 'production';
  //const version = process.env.VERSION;
  /*
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/Service-worker.js')
        .then(() => console.log('Service Worker registrado'))
        .catch((error) => console.error('Error al registrar el Service Worker:', error));
    }
  }, []);*/

  useEffect(() => {
    const checkNetworkConnection = async () => {
      try {
        const response = await fetch('https://www.google.com', { mode: 'no-cors' });
        if (response) {
          setIsNetworkModalOpen(false);
        }
      } catch (error) {
        setIsNetworkModalOpen(true);
      }
    };

    checkNetworkConnection();

    const interval = setInterval(checkNetworkConnection, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      />
      <Router>
        <PrimeReactProvider>
          <ModalNetwork
            isOpen={isNetworkModalOpen}
            message={"No internet connection. Please check your network settings."}
            onClose={() => setIsNetworkModalOpen(false)}
            onConfirm={() => setIsNetworkModalOpen(false)} />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/recoverPassword" element={<RecoverPassword />} />
            <Route path="/restorePassword/:hash" element={<RestorePassword />} />
            <Route path="/updateUser" element={<PrivateRoute element={UpdateUser} />} />
            <Route path="/fields" element={<PrivateRoute element={Fields} />} />
            <Route path="/settings" element={<PrivateRoute element={Settings} />} />
            <Route path="/field/:fieldId" element={<PrivateRoute element={Field} />} />
            <Route path="/create/field/qr" element={<PrivateRoute element={QRScan} />} />
            <Route path="/create/field/license" element={<PrivateRoute element={AddLicense} />} />
            <Route path="/create/field/form" element={<PrivateRoute element={FormField} />} />
            <Route path="/link/sensor" element={<PrivateRoute element={LinkSensor} />} />
            <Route path="/notifications" element={<PrivateRoute element={Notifications} />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </PrimeReactProvider>
      </Router>
    </>
  );
}

export default App;
