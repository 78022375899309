import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { PreLoginWrapper } from './PreLoginWrapper';
import { restorePassword, changePassword } from '../actions/userActions';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';


export const RestorePassword = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [hashResponse, setHashResponse] = useState(null);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [errorUrl, setErrorUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { hash } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const data = await restorePassword(hash);
            if (typeof data === 'string') {
                setErrorUrl(t(`restore_password.${data}`));
            } else {
                setHashResponse(data.hash);
                setEmail(data.email);
            }
        };
        fetchData();
    }, [hash]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        changePassword(hashResponse, email, password, navigate);
    };

    return (
        <PreLoginWrapper error={error}>
            {errorUrl ? (
                <>
                    <p className="p-component mb-0 font-semibold w-full">{errorUrl}</p>
                    <Button 
                        className='bg-primary w-full mt-5' 
                        disabled={loading} 
                        label={t('restore_password.go_to_recover_password')}
                        onClick={() => navigate('/recoverPassword')}
                    />
                </>
            ) : (
                <>
                    <p className="p-component mb-5 font-semibold w-full">{t('restore_password.info_message')}</p>
                    <form className='w-full' onSubmit={handleSubmit}>
                        <IconField className="w-full" iconPosition="left">
                            <InputIcon className="pi pi-lock ml-2" style={{ color: 'black', zIndex: 1 }}/>
                            <Password
                                placeholder={t('restore_password.placeholder_password')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                toggleMask
                                inputClassName="w-full pl-6"
                                className="w-full"
                                pt={{ iconField: { root: { className: 'w-full' } } }} 
                                required
                                promptLabel={t('restore_password.prompt_label')}
                                weakLabel={t('restore_password.weak_label')}
                                mediumLabel={t('restore_password.medium_label')}
                                strongLabel={t('restore_password.strong_label')}
                            />
                        </IconField>

                        <Button 
                            className='bg-primary w-full mt-5' 
                            disabled={loading} 
                            label={loading ? t('restore_password.loading_message') : t('restore_password.submit')}
                        />
                    </form>
                </>
            )}
        </PreLoginWrapper>
    );
};
