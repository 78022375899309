import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../actions/userActions';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import 'react-phone-number-input/style.css';
import { Checkbox } from 'primereact/checkbox';
import { PostLoginWrapper } from './PostLoginWrapper';
import { Dropdown } from 'primereact/dropdown';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { getUser } from '../actions/userActions';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

export const UpdateUser = () => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [error, setError] = useState('');
    const [receibeAdvertising, setReceibeAdvertising] = useState(false);
    const [cifNif, setCifNif] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [loyaltyProgramId, setLoyaltyProgramId] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        localStorage.setItem('remindLaterUpdateUserTimestamp', new Date().getTime());
        const fetchUserData = async () => {
            try {
                const userData = await getUser();
                setId(userData.id);
                setName(userData.first_name || '');
                setSurname(userData.last_name || '');
                setPhone(userData.phone || '');
                setStreetNumber(userData.street_number || '');
                setCity(userData.city || '');
                setPostalCode(userData.zip_code || '');
                setReceibeAdvertising(userData.receive_advertising || false);
                setCifNif(userData.nif || '');
                setCompanyName(userData.company || '');
                setLoyaltyProgramId(userData.loyalty_id || '');
                setSelectedCountry(userData.country || '');
                const countriesData = t('countries:data', { returnObjects: true });
                setCountries(countriesData)
                const selectedCountryData = countriesData.find(country => country.code === userData.country);
                setSelectedCountry(selectedCountryData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        fetchUserData();

    }, [localtoken, navigate]);

    const handleNext = () => {
        setError('');
        if (step === 1 && (name === '' || surname === '')) {
            return;
        }
        setError('');
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        
        let countryCode = '';
        if (selectedCountry !== undefined) {
            countryCode = selectedCountry.code;
        }

        const response = await updateUser(id, name, surname, receibeAdvertising, phone, streetNumber, city, postalCode, countryCode, cifNif, companyName, loyaltyProgramId, navigate);
        if (typeof response === 'string') {
            setError(t(`update_user.${response}`)); // Traducir el mensaje de error aquí
        }
        
    };

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <Image alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} style={{ width: '18px', marginRight: '16px', marginLeft: '6px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span className="p-input-icon-left" style={{ display: 'flex', alignItems: 'center'}}>
                <i className="pi pi-globe" style={{ marginLeft: '7px', color: 'black'  }} />
                <span style={{ marginLeft: '39px' }}>{props.placeholder}</span>
            </span>
        );
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <Image alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <PostLoginWrapper error={error}>
            <form className='w-11' onSubmit={handleSubmit}>
                {step === 1 && (
                    <>
                        <p className="p-component font-semibold w-full text-center mb-5">{t('update_user.step1')}</p>
                        <IconField className="w-full" iconPosition="left">
                            <InputIcon className="pi pi-user ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_name')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full pl-6"
                                required
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-user ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_surname')}
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                className="w-full pl-6"
                                required
                            />
                        </IconField>
                        <div className="flex align-items-center mt-3">
                            <Checkbox
                                inputId="loyalty-checkbox"
                                checked={receibeAdvertising}
                                onChange={(e) => setReceibeAdvertising(e.checked)}
                            />
                            <label htmlFor="loyalty-checkbox" className="ml-3">
                                {t('update_user.checkbox_message')}
                            </label>
                        </div>
                        <Button
                            label={t('update_user.next')}
                            onClick={handleNext}
                            className="bg-primary mt-5 w-5"
                            style={{ float: 'right' }}
                        />
                    </>
                )}
                {step === 2 && (
                    <>
                        <p className="p-component font-semibold w-full text-center mb-5">{t('update_user.step2')}</p>
                        <IconField className="w-full" iconPosition="left">
                            <InputIcon className="pi pi-id-card ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_cif_nif')}
                                value={cifNif}
                                onChange={(e) => setCifNif(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-building ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_company_name')}
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-key ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_loyalty_program_id')}
                                value={loyaltyProgramId}
                                onChange={(e) => setLoyaltyProgramId(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <div className="flex justify-content-between w-full mt-5">
                            <Button 
                                className="text-color-secondary" 
                                link 
                                label={<><i className="pi pi-arrow-left mr-2"></i> {t('register.back')}</>} 
                                onClick={handleBack}
                            />
                            <Button
                                label={t('update_user.next')}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNext();
                                }}
                                className="bg-primary w-5"
                            />
                        </div>
                    </>
                )}
                {step === 3 && (
                    <>
                        <p className="p-component font-semibold w-full text-center mb-5">{t('update_user.step3')}</p>
                        <Dropdown 
                            filter
                            value={selectedCountry} 
                            onChange={(e) => setSelectedCountry(e.value)} 
                            options={countries} 
                            optionLabel="name" 
                            placeholder={t('update_user.placeholder_country')} 
                            valueTemplate={selectedCountryTemplate} 
                            itemTemplate={countryOptionTemplate} 
                            className="w-full" 
                            dropdownIcon={(opts) => {
                                return opts.iconProps['data-pr-overlay-visible'] ? <FaChevronRight {...opts.iconProps} /> : <FaChevronDown {...opts.iconProps} />;
                            }}
                        />
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-map-marker ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_city')}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-map-marker ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_street_number')}
                                value={streetNumber}
                                onChange={(e) => setStreetNumber(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-map-marker ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_postal_code')}
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                className="w-full pl-6"
                            />
                        </IconField>
                        <IconField className="w-full mt-3" iconPosition="left">
                            <InputIcon className="pi pi-phone ml-2" style={{ color: 'black' }}/>
                            <InputText
                                placeholder={t('update_user.placeholder_phone')}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="w-full pl-6"
                                pattern="^\d{6,}$"
                                title={t('update_user.phone_pattern')}
                            />
                        </IconField>
                        <div className="flex justify-content-between w-full mt-5">
                            <Button 
                                className="text-color-secondary" 
                                link 
                                label={<><i className="pi pi-arrow-left mr-2"></i> {t('register.back')}</>} 
                                onClick={handleBack}
                            />
                            <Button
                                label={loading ? t('update_user.loading_message') : t('update_user.update_message')}
                                disabled={loading}
                                className="bg-primary w-5"
                            />
                        </div>
                    </>
                )}
            </form>
            <div className="flex flex-column align-items-center justify-content-between mt-5">
               <a className="text-white no-underline cursor-pointer" onClick={() => navigate('/fields')}>{t('update_user.go_to_fields')}</a>
            </div>
        </PostLoginWrapper>
    );
};
